@import "../../global.scss";

.login {
  color: #fff;
  font-family: "Poppins";
  .form {
    max-width: 620px;
    display: block;
    margin: 80px auto;
    position: relative;
    z-index: 99;
    .title {
      font-size: 24px;
      font-weight: 600;
      padding-bottom: 30px;
    }
    .label {
      font-size: 14px;
      font-weight: 400;
      text-align: left;
      display: flex;
    }
    .login-form {
      .login-button {
        font-size: 14px;
        cursor: pointer;
      }
    }
  }
}