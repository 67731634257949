@import "../../global.scss";

.cancellation-table {
  .table {
    thead {
      tr {
        background: $theme-yellow;
        color: black;
        th {
          border-bottom-width: 0px !important;
        }
      }
    }
    tbody {
      border-top: 0px;
      tr {
        background: $light-grey;
        td {
          border-bottom-color:$theme-background;
        }
      }
    }
  }
}
