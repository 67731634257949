@import "../../global.scss";

.input-container {
  .error {
    font-size: 12px;
    margin-top: 5px;
    font-weight: 400;
    text-align: left;
    margin-bottom: 0;
  }
  .label {
    font-size: 14px;
    font-weight: 400;
  }
  .input {
    background: #313235;
    padding: 10px 20px;
    border-radius: 20px;
    border: none;
    color: white;
    font-family: "Poppins";
    font-size: 14px;
    outline: none;
    width: 100%;

    &::placeholder {
      opacity: 0.7;
      color: white;
    }
  }
}

.date-picker-input {
  text-align: left;
  .label {
    font-size: 14px;
    font-weight: 400;
  }
  .error {
    font-size: 12px;
    margin-top: 5px;
    font-weight: 400;
    text-align: left;
    margin-bottom: 0;
  }
  .date-picker {
    background: #313235;
    padding: 10px 20px;
    border-radius: 20px;
    border: none;
    color: white;
    font-family: "Poppins";
    font-size: 14px;
    outline: none;
    width: 100%;
    &::placeholder {
      opacity: 0.7;
      color: white;
    }
    &:disabled{
      cursor: not-allowed;
    }
  }

  .react-datepicker-popper {
    z-index: 1000 !important;
    .react-datepicker__navigation {
      margin: 10px 0;
    }
    .react-datepicker__header {
      padding: 12px 0;
      background: $light-grey;
      .react-datepicker__current-month {
        color: white !important;
        font-family: "Poppins";
      }
      .react-datepicker__day-names,
      .react-datepicker__day-name {
        color: white;
        font-family: "Poppins";
      }
    }

    .react-datepicker__month {
      background: $light-grey;
      margin: 0px;
      padding: 0.4em;
      .react-datepicker__week {
        .react-datepicker__day {
          color: white;
          font-family: "Poppins";
        }

        .react-datepicker__day:hover {
          background: $theme-yellow;
        }

        .react-datepicker__day--selected {
          background: $theme-yellow;
        }

        .react-datepicker__day--disabled {
          opacity: 0.3;

          &:hover {
            background: none !important;
            cursor: not-allowed;
          }
        }
      }
    }

    .react-datepicker-time__header {
      color: #fff;
    }

    .react-datepicker__time-container
      .react-datepicker__time
      .react-datepicker__time-box
      ul.react-datepicker__time-list {
      background: $light-grey;
      color: #fff;
      font-family: "Poppins";
    }

    .react-datepicker__time-container
      .react-datepicker__time
      .react-datepicker__time-box
      ul.react-datepicker__time-list
      li.react-datepicker__time-list-item:hover {
      background: $theme-background;
    }

    .react-datepicker__time-container
      .react-datepicker__time
      .react-datepicker__time-box
      ul.react-datepicker__time-list
      li.react-datepicker__time-list-item--disabled {
      opacity: 0.3;
      cursor: not-allowed;
    }
  }
}

.radio-button {
  .form-check-label {
    font-size: 14px;
    margin-left: 5px;
  }

  input[type="radio"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: inline-block;
    width: 18px;
    height: 18px;
    padding: 0px;
    background-clip: content-box;
    border: 5px solid #fff;
    background-color: #ffffff;
    border-radius: 50%;
    cursor: pointer;
  }

  input[type="radio"]:checked {
    border: 5px solid $theme-yellow;
  }
}

.select-input-container {
  text-align: left;

  .label {
    font-size: 14px;
    font-weight: 400;
  }

  .select-input {
    background: #313235;
    padding: 10px 20px;
    border-radius: 20px;
    border: none;
    color: white;
    font-family: "Poppins";
    font-size: 14px;
    outline: none;
    width: 100%;

    &::placeholder {
      opacity: 0.7;
      color: white;
    }
  }

  .error {
    font-size: 12px;
    margin-top: 5px;
    font-weight: 400;
    text-align: left;
    margin-bottom: 0;
  }
}
