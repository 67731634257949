@import "../../global.scss";

.register-button {
  outline: none !important;
  font-family: "FuturaLight" !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  letter-spacing: 0.04em !important;
  background: #ffa800 !important;
  border-radius: 22.5px !important;
  border: none !important;
  padding: 10px 35px !important;
  color: black !important;
}
.call-button {
  outline: none !important;
  font-family: "FuturaLight" !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  letter-spacing: 0.04em !important;
  background: transparent !important;
  border-radius: 22.5px !important;
  border: 2px solid #fff !important;
  padding: 10px 35px !important;
  color: white !important;
  span {
    margin-right: 10px;
  }
}
.google-sign-button {
  background: white !important;
  width: 100%;
  border-radius: 25px !important;
  border: none !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  img {
    margin-right: 15px;
  }
  p {
    color: $theme-background !important;
    font-family: "FuturaMedium" !important;
    font-size: 16px !important;
    margin: 0px;
  }
}

.search-button {
  outline: none !important;
  font-family: "Poppins" !important;
  font-size: 15px !important;
  font-weight: 500 !important;
  background: #ffa800 !important;
  border-radius: 16px !important;
  border: none !important;
  padding: 10px 40px !important;
  color: black !important;
  width: 100%;
  &.btn-primary:disabled{
    cursor: not-allowed;
    pointer-events: auto;
  }
  .spinner-border{
    width: 1.5rem;
    height: 1.5rem;
  }
}

.back-button {
  outline: none !important;
  font-family: "Poppins" !important;
  font-size: 15px !important;
  font-weight: 500 !important;
  background: transparent !important;
  border-radius: 16px !important;
  border: 1px solid #ffa800 !important;
  padding: 10px 40px !important;
  color: white !important;
  width: 100%;
  &.btn-primary:disabled{
    cursor: not-allowed;
    pointer-events: auto;
  }
}