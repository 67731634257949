.my-bookings {
    color: #fff;
    font-family: "Poppins";
    .bookings-card {
      max-width: 720px;
      display: block;
      margin: 80px auto;
      position: relative;
      z-index: 99;
      .title{
          font-weight: 600;
      }
    }
}