@import "../../global.scss";

.dormitory-booking {
  color: #fff;
  font-family: "Poppins";
  .form {
    max-width: 620px;
    display: block;
    margin: 80px auto;
    position: relative;
    z-index: 99;
    .title {
      font-size: 24px;
      font-weight: 600;
      padding-bottom: 30px;
    }
    .label {
      font-size: 14px;
      font-weight: 400;
      text-align: left;
      display: flex;
    }
    .login-button {
      color: #fff;
      text-decoration: none;
    }
    .login-form {
      .login-button {
        font-size: 14px;
        cursor: pointer;
      }
    }
    .booking-preview {
      .title {
        font-size: 16px;
        font-weight: 500;
        padding-bottom: 0px;
      }
      .date-label {
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 6px;
      }
      .date {
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 6px;
      }
      .time {
        font-size: 14px;
        font-weight: 500;
        opacity: 0.8;
        margin-bottom: 0px;
      }
      .sub-heading {
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 5px;
      }
      .caption {
        font-size: 14px;
        font-weight: 400;
      }
      .price {
        font-size: 18px;
        font-weight: 600;
      }
      .terms-warning {
        font-size: 14px;
        a {
          color: $theme-yellow;
          text-decoration: none;
        }
      }
    }
  }
  .gradient-blur {
    position: absolute;
    opacity: 0.5;
    -webkit-filter: blur(100px);
    filter: blur(100px);
    width: 100%;
    top: 70vh;
    .gradient-yellow {
      position: absolute;
      width: 211px;
      height: 211px;
      background: $theme-yellow !important;
      left: 400px;
    }
    .gradient-blue {
      position: absolute;
      width: 211px;
      height: 211px;
      background: $theme-blue !important;
      left: 300px;
    }
    .gradient-orange {
      position: absolute;
      width: 211px;
      height: 211px;
      background: $theme-orange !important;
      left: 150px;
    }
  }
}
