@import "../../global.scss";

.home {
  .home-section {
    padding: 115px 0;
    .title {
      font-family: "FuturaBold";
      font-size: 45px;
      max-width: 700px;
    }
    .address-card {
      background: #27292c;
      border-radius: 19px;
      max-width: 358px;
      height: 87px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 20px;
      padding-top: 10px;
      p {
        text-align: left;
        color: #fff;
        font-family: "FuturaLight";
        font-size: 16px;
        letter-spacing: 0.04em;
        line-height: 21px;
      }
    }
    .description {
      font-family: "FuturaLight";
      max-width: 420px;
      font-size: 16px;
      padding: 34px 0 40px;
    }
    .slider {
      z-index: 10 !important;
      .slick-prev,
      .slick-next {
        display: none !important;
      }
      .slick-dots li button::before {
        color: $theme-yellow;
        font-size: 14px;
      }
      .slider-img {
        object-fit: cover;
        width: 550px;
        height: 520px;
        opacity: 0.82;
        border-radius: 41px;
      }
      .bg-gradient-blur {
        z-index: -1;
        position: absolute;
        margin-top: -100px;
        opacity: 0.3;
        -webkit-filter: blur(100px);
        filter: blur(100px);
        .color-gradient-yellow {
          width: 211px;
          height: 211px;
          background: $theme-yellow !important;
          margin-left: -100px;
          border-radius: 50%;
        }
        .color-gradient-blue {
          width: 150px;
          height: 150px;
          background: $theme-blue;
          margin-left: 125px;
          margin-top: -150px;
          border-radius: 50%;
        }
        .color-gradient-orange {
          width: 211px;
          height: 211px;
          background: $theme-orange;
          margin-top: -200px;
          margin-left: 300px;
          border-radius: 50%;
        }
      }
    }
  }
  .about-section {
    padding: 75px 0;
    .gradient-white {
      position: absolute;
      width: 512px;
      height: 510px;
      margin-top: -55px;
      left: 0;
      background: linear-gradient(116.69deg, #282a2e 30.04%, #202225 64.18%);
    }
    .title {
      font-family: FuturaBold;
      font-weight: 700;
      font-size: 84px;
      color: #fff;
      opacity: 0.03;
      -webkit-user-select: none;
      -ms-user-select: none;
      user-select: none;
      margin-bottom: 0px;
    }
    .sub-title {
      font-family: FuturaBold;
      color: #fff;
      font-size: 35px;
      display: inline-flex;
      align-items: center;
      margin-bottom: 20px;
      position: relative;
    }
    .description {
      font-family: FuturaLight;
      font-size: 16px;
      line-height: 25px;
      color: #fff;
      opacity: 0.74;
      max-width: 450px;
      margin-bottom: 0px;
    }
    .read-more-button {
      font-family: FuturaLight;
      font-weight: 450;
      font-size: 18px;
      color: $theme-yellow;
      opacity: 0.74;
      cursor: pointer;
      margin-top: 20px;
      -webkit-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
    .about-section-right {
      .card-container {
        flex-wrap: inherit !important;
        overflow-x: scroll;
        padding: 30px 0;
        -ms-overflow-style: none;
        scrollbar-width: none;
        scroll-behavior: smooth;
      }
      .card-container::-webkit-scrollbar {
        display: none;
      }
    }
  }
  .features-section {
    padding: 75px 0;
    .title {
      font-family: FuturaBold;
      font-weight: 700;
      font-size: 84px;
      color: #fff;
      opacity: 0.03;
      -webkit-user-select: none;
      -ms-user-select: none;
      user-select: none;
      margin-bottom: 0;
    }
    .sub-title {
      font-family: FuturaBold;
      color: #fff;
      font-size: 35px;
      display: inline-flex;
      align-items: center;
      text-align: center;
    }
    .features-container {
      height: 513px;
      margin-top: 100px;
      padding: 70px !important;
      background: linear-gradient(116.69deg, #282a2e 9.04%, #202225 64.18%);
      border-radius: 21px;
      .slick-prev:before,
      .slick-next:before {
        color: $theme-yellow !important;
        font-size: 32px;
      }
      &::-webkit-scrollbar {
        height: 0px;
      }
      .gradient-blur {
        position: absolute;
        opacity: 0.5;
        -webkit-filter: blur(100px);
        filter: blur(100px);
        .gradient-yellow {
          position: absolute;
          width: 211px;
          height: 211px;
          background: $theme-yellow !important;
          right: 400px;
          margin-top: 250px;
        }
        .gradient-blue {
          position: absolute;
          width: 211px;
          height: 211px;
          background: $theme-blue !important;
          right: 300px;
          margin-top: 250px;
        }
        .gradient-orange {
          position: absolute;
          width: 211px;
          height: 211px;
          background: $theme-orange !important;
          right: 150px;
          margin-top: 250px;
        }
      }
    }
  }
  .pricing-section {
    padding: 75px 0;
    text-align: center;
    .title {
      font-family: FuturaBold;
      color: #fff;
      font-size: 35px;
      display: inline-flex;
      align-items: center;
      margin-bottom: 20px;
    }
    .description {
      font-family: FuturaLight;
      font-size: 20px;
      line-height: 35px;
      color: #fff;
      opacity: 0.74;
      max-width: 700px;
      margin: 0 auto;
    }
    .price-card-container {
      padding-top: 50px;
    }
  }
  .testimonial-section {
    padding: 75px 0;
    .gradient-blur {
      position: absolute;
      opacity: 0.5;
      -webkit-filter: blur(100px);
      filter: blur(100px);
      margin-top: 200px;
      left: -150px;
      .gradient-yellow {
        position: absolute;
        width: 150px;
        height: 150px;
        background: $theme-yellow !important;
        left: 400px;
      }
      .gradient-blue {
        position: absolute;
        width: 150px;
        height: 150px;
        background: $theme-blue !important;
        left: 300px;
      }
      .gradient-orange {
        position: absolute;
        width: 150px;
        height: 150px;
        background: $theme-orange !important;
        left: 150px;
      }
    }
  }
  .footer {
    .heading {
      font-family: FuturaLight;
      font-size: 27px;
      color: #fff;
      margin-bottom: 40px;
    }
    .address {
      max-width: 450px;
      font-family: FuturaLight;
      font-size: 18px;
      line-height: 40px;
      color: #fff;
      opacity: 0.74;
      text-decoration: none;
    }
    .contact {
      font-family: FuturaLight;
      font-size: 16px;
      color: #fff;
      opacity: 0.74;
    }
    .copyright {
      margin: 20px 0;
      height: 53px;
      background: #292b2f;
      border-radius: 18px;
      font-family: FuturaLight;
    }
  }
}

@media (max-width: 600px) {
  .home {
    .home-section {
      .slider {
        .slider-img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .about-section {
      padding: 50px 0;
      .title {
        font-size: 75px;
      }
    }
    .features-section {
      padding: 50px 0;
      .title {
        font-size: 75px;
      }
      .sub-title {
        margin-top: 20px;
        .yellow-dot {
          display: none;
        }
      }
      .features-container {
        padding: 40px !important;
      }
    }
    .pricing-section {
      padding: 50px 0;
    }
    .testimonial-section {
      padding: 50px 0;
    }
  }
}
