$theme-background: #202225;
$light-grey: #292b2f;
$theme-yellow: #ffa800;
$theme-orange: #ff3d00;
$theme-blue: #00a3ff;

.yellow-dot {
  width: 12px;
  height: 12px;
  background: #ffa800;
  border-radius: 100px;
  margin-left: 15px;
  margin-top: 15px;
}

html,
body {
  overflow-x: hidden;
  position: relative;
  height: 100%;
  background-color: $theme-background !important;
  text-align: center;
  scroll-behavior: smooth !important;
  min-height: 100vh;
}

@media (max-width: 600px) {
  .container {
    padding: 0 15px;
  }
}
