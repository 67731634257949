@import "../../global.scss";

.about-card {
  width: 260px !important;
  height: 264px !important;
  background: #292b2f !important;
  border-radius: 32px !important;
  box-shadow: none !important;
  .card-body {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    .bg-round {
      width: 75px;
      height: 75px;
      background: #303237;
      border-radius: 50%;
      position: absolute;
      left: 35%;
      top: 20%;
    }
    svg {
      height: 75px;
      width: 75px;
      position: relative;
    }
    .card-title {
      padding-top: 20px;
      font-family: FuturaLight;
      color: #fff;
      opacity: 0.74;
      font-size: 20px;
    }
  }
}
.features-card {
  text-align: left;
  background: transparent !important;
  border: none !important;
  border-right: 1px solid rgba(0, 0, 0, 0.12) !important;
  .card-body {
    margin: auto !important;
    .card-title {
      font-family: FuturaBold;
      font-size: 20px;
      line-height: 23px;
      color: #fff;
    }
    .description {
      font-family: FuturaLight;
      color: #fff;
      font-size: 16px;
      opacity: 0.74;
    }
    .card-image {
      margin-top: 20px;
      width: 355px;
      height: 254px;
      opacity: 0.82;
      object-fit: cover;
      border-radius: 41px;
    }
    .enroll-button {
      margin-top: -25px;
      position: relative;
      left: 50px;
    }
  }
}

.pricing-card {
  min-width: 200px !important;
  min-height: 335px !important;
  background: $light-grey !important;
  border-radius: 19px !important;
  .plan-name {
    font-family: FuturaBold;
    font-size: 20px;
    line-height: 33px;
    color: #fff;
  }
  .plan-features {
    font-family: FuturaLight;
    font-size: 18px;
    color: #fff;
    opacity: 0.74;
    line-height: 40px;
    padding: 10px 0;
    li {
      list-style-type: none;
    }
  }
  .plan-price {
    background: hsla(0, 0%, 95.7%, 0.03529411764705882);
    border-radius: 20.5px;
    font-family: FuturaBold;
    font-size: 14px;
    color: #fff !important;
  }
}

.testimonial-card {
  height: 217px !important;
  background: #292b2f !important;
  border-radius: 19px !important;
  padding: 0 40px;
  .name {
    font-family: FuturaLight;
    font-size: 18px;
    color: #fff;
    margin-bottom: 10px;
    text-align: left;
  }
  .position {
    font-family: FuturaLight;
    font-size: 16px;
    color: #fff;
    text-align: left;
  }
}

.booking-preview-card {
  background: #313235 !important;
  padding: 10px;
  border-radius: 20px !important;
  margin-bottom: 30px;
  font-family: "Poppins";
  .card-body {
    padding: 0px;
    .preview-image {
      border-radius: 12px;
      object-fit: cover;
      cursor: pointer;
    }
    .image-gallery-icon {
      position: absolute;
      margin-left: 15px;
      margin-top: -20px;
      cursor: pointer;
    }
    .type {
      font-weight: 500;
      margin-bottom: 3px;
    }
    .price {
      font-weight: 400;
      font-size: 14px;
      margin-bottom: 2px;
    }
  }
}

.booking-history-card {
  background: #313235 !important;
  padding: 10px;
  border-radius: 20px !important;
  margin-bottom: 30px;
  font-family: "Poppins";
  .card-body {
    padding: 0px;
    .preview-image {
      border-radius: 12px;
      object-fit: cover;
    }
    .type {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 5px;
    }
    .booked-date,
    .guest-count {
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 3px;
    }
    .booking-status {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 3px;
    }
    .booking-code,
    .booking-amount {
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 3px;
    }
    .view-button {
      font-size: 14px;
      font-weight: 400;
      margin-top: 10px;
      margin-bottom: 0px;
      a {
        text-decoration: none;
        color: $theme-yellow;
      }
    }
  }
}

@media (max-width: 768px) {
  .booking-preview-card {
    padding: 30px 20px;
    .card-body {
      .image-gallery-icon {
        margin-left: -25px;
        margin-top: 55px;
      }
    }
  }
  .booking-history-card {
    padding: 30px;
    .card-body {
      text-align: left;
      .booking-status {
        font-size: 14px;
      }
      .view-button {
        outline: none !important;
        font-family: "Poppins" !important;
        font-size: 15px !important;
        font-weight: 500 !important;
        background: #ffa800 !important;
        border-radius: 16px !important;
        border: none !important;
        padding: 10px 40px !important;
        width: 100%;
        text-align: center;
        a {
          color: black !important;
        }
      }
    }
  }
}

@media (min-width: 1198px) {
  .testimonial-card {
    .name {
      white-space: nowrap;
    }
  }
}

@media (max-width: 600px) {
  .features-card {
    .card-body {
      .card-image {
        width: 250px;
        height: 250px;
      }
    }
  }
  .testimonial-card {
    display: flex !important;
    align-items: center;
    img {
      width: 120px !important;
    }
  }
}
