.booking-details {
  color: #fff;
  font-family: "Poppins";
  .details-card {
    max-width: 720px;
    display: block;
    margin: 80px auto;
    position: relative;
    z-index: 99;
    .title {
      font-weight: 600;
      font-size: 22px;
    }
    .booking-id {
      font-weight: 400;
    }
    .payment-failure-error {
      margin-bottom: 20px;
      color: red;
      font-weight: 600;
    }
    .address {
      .title {
        font-weight: 600;
        font-size: 20px;
        margin-bottom: 15px;
      }
      .sub-heading {
        font-weight: 600;
        font-size: 16px;
      }
      .caption {
        font-weight: 400;
        font-size: 14px;
      }
    }
    .guest-details {
      .head-title {
        font-weight: 400;
        font-size: 14px;
        margin-bottom: 5px;
      }
      .head-caption {
        font-weight: 600;
        font-size: 16px;
      }
    }
    .payment-details {
      .heading {
        font-weight: 600;
        font-size: 20px;
        margin-bottom: 15px;
      }
      .head-caption {
        font-weight: 500;
        font-size: 16px;
        margin-bottom: 5px;
      }
    }
    .cancel-button {
      cursor: pointer;
    }
  }
}
