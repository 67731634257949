body {
  margin: 0;
  font-family: FuturaLight;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: FuturaLight;
}

@font-face {
  font-family: "FuturaLight";
  src: local("FuturaLight"), url(./fonts/FuturaLight.woff) format("woff");
}
@font-face {
  font-family: "FuturaMedium";
  src: local("FuturaMedium"), url(./fonts/FuturaBold.woff) format("woff");
}
@font-face {
  font-family: "FuturaBold";
  src: local("FuturaBold"), url(./fonts/FuturaPTBold.otf) format("OpenType");
}
@font-face {
  font-family: "FuturaExtraBold";
  src: local("FuturaExtraBold"),
    url(./fonts/FuturaPTExtraBold.otf) format("OpenType");
}
