@import "../../global.scss";

.nav-bar {
  background-color: $light-grey;
  border-radius: 18px !important;
  width: 100%;
  margin: auto;
  height: 80px;
  z-index: 100;
  top: 40px;
  user-select: none;
  padding: 0 20px;
  .logo {
    font-family: "FuturaLight";
    text-decoration: none;
    img {
      margin-right: 10px;
    }
  }
  .nav-link {
    font-family: FuturaLight;
    font-size: 16px;
    color: #fff;
    margin: 0 20px;
  }
  .navbar-nav {
    align-items: center;
    background: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
    margin-top: 3px;
    padding-bottom: 10px;
  }
}

@media(max-width:992px){
  .navbar{
    .navbar-nav {
      background: $theme-background!important;
      padding-bottom: 25px;
      margin-top: 11px;
      border-bottom-left-radius: 25px;
      border-bottom-right-radius: 25px;
      .nav-link{
        margin: 5px 20px;
      }
    }
  }
}
