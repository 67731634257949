@import "../../global.scss";

.modal {
  .modal-content {
    background-color: $light-grey;
    color: #fff;
    font-family: "Poppins";
    .btn-close {
      color: #fff;
    }
    .modal-body {
      padding: 2rem 1rem;
    }
  }
}

.images-preview-modal {
  .modal-header {
    border-bottom: 0px;
  }
  .modal-content {
    padding: 0 25px;
    .slick-slide img {
      display: unset;
    }
    .slick-prev:before,
    .slick-next:before {
      color: $theme-yellow;
      font-size: 32px;
    }
    .slider-img {
      width: 100%;
      height: 100%;
      max-height: 75vh;
      object-fit: contain;
      padding: 0 15px;
    }
    .slick-thumb img {
      width: 50px;
      height: 50px;
      object-fit: fill;
    }
  }
}
